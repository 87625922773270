@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#colorsidebar,
#default-sidebar #colorheader {
  background-color: #283342;
}

.handleBack {
  background-color: #edf1f5;
}

.handleDescription {
  color: #1d242e;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.loginBox {
  border-radius: 30px;
  background: #fff;
  box-shadow: 10px 10px 50px 10px #000;
}

.loginBox button {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.68px;
}

#checkbox {
  border: 2px solid #2de3ad;
}

/* Active class styles */
.active {
  background-color: #009099;
  /* Add your desired background color */
  color: white;
  /* Add your desired text color */
  /* Add any other styles you want for the active menu item */
}

.aboveimg {
  text-align: center;
  position: absolute;
  top: 19rem;
}

.abovetext {
  position: absolute;
  top: 21.2rem;
}

.containerAll {
  position: relative;
  left: 80%;
}

/* Dropdown Button */
.btn {
  font-size: 16px;
}

.handleHeader {
  font-size: 26px;
  font-weight: bold;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: absolute;
  display: contents;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  border-radius: 5px;
  border: 2px solid rgb(189, 186, 186);
  position: absolute;
  top: 19%;
  right: 8%;
  background-color: #ffffff;
  min-width: 160px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  /* display: inline-block; */
  padding: 8px 1px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* pop up on adding button */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 40%;
  right: 40%;
  width: 280px;
}

/* The popup form - hidden by default */
.form-popup {
  display: none;
  position: fixed;
  top: 35%;
  right: 35%;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  width: 389px;
  height: 286px;
  flex-shrink: 0;
  padding: 10px;
  background-color: white;
}

.paginationbox {
  width: 160px;
  position: absolute;
  right: 10px;
}

/* When the inputs get focus, do something */
.form-container input[type="text"]:focus,
.form-container input[type="password"]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #04aa6d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 83px;
  height: 33px;
  flex-shrink: 0;
  opacity: 0.8;
}
.btn2 {
  background-color: #04aa6d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 83px;
  height: 33px;
  flex-shrink: 0;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.btn {
  margin-left: 15px;
  margin-right: 15px;
}

.bottomButtom {
  justify-content: center;
  padding-top: 4%;
}

.textbox {
  border-radius: 4px;
  border: 0.2px solid #35404f;
  margin: 5px 0 10px 0;
  background-color: #e3ebf3;
  width: 196.857px;
  height: 38px;
  padding: 15px;
  flex-shrink: 0;
}

.imgfix {
  position: absolute;
  top: 35%;
  left: 17%;
}

.textaddimg {
  position: absolute;
  top: 48%;
  left: 12.5%;
}

.redbutton {
  /* position: relative;
  top: 0%; */
  /* position: absolute;
  right: 4.2%; */
  /* top: 0px; */
}

/* upload button */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploadbtn {
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#multiselectContainerReact {
  /* margin-bottom: 6px; */
  float: none;
}

.displayBlock {
  display: block;
}

.otheroptions {
  font-size: 15px;
}

/* Overriding element hover */
.optionContainer li:hover,
.optionContainer .highlight {
  background: rgb(39, 161, 223);
  color: #fff;
}

/* Ends overriding */

/* Placehoolder Color Change */
.searchBox#css_custom_input::placeholder {
  color: red;
}

.handleBoxPara {
  color: #1d242e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.handleBoxTop {
  color: #1d242e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 110% */
}

.confirmed,
.pending {
  padding-bottom: 14px;
}

.text-red {
  color: red;
  font-size: 14px;
  font-weight: 400;
}

.text-yellow {
  color: #fed600;
  font-size: 14px;
  font-weight: 400;
}

.text-green {
  color: #00b954;
  font-size: 14px;
  font-weight: 400;
}

.activitycom {
  width: 250px;
  height: 286px;
  border: #000;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 3;
  background-color: white;
}

.activityimg {
  /* border-top-left-radius: 20px; */
  height: 138px;
  width: 250px;
}

.activitytitle {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  padding-left: 6px;
  z-index: 7;
  background-color: white;
}

.activitysub {
  font-style: italic;
  color: #555;
  font-size: 14px;
  font-weight: 450;
  padding-left: 6px;
  padding-top: 0px;
}

.actcenterbtn {
  margin: 2px;
}

.editbtn {
  position: absolute;
  right: 55px;
}

.tablefont {
  font-family: Poppins;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.box-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
}

.table-text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.table-heading {
  color: #1d242e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  /* font-weight: lighter; */
}

.centered-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-upload {
  margin: 10px;
  width: 100px;
  height: 100px;

  border-radius: 10px;
  background-color: white;
  justify-content: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;

  img {
    max-width: 100px;
    max-height: 100px;
  }
}

.img-upload-table {
  max-height: 640px;
}

.delete-button-img-upload {
  position: absolute;
  top: 4px;
  right: 4px;
}

.css-1xc3v61-indicatorContainer {
  display: none;
}

.tagimg {
  height: 100px;
  width: 100px;
}

div.css-kz6gj7-control {
  z-index: 0;
}

div.css-1f43avz-a11yText-A11yText {
  z-index: 0;
}

.modal-content {
  justify-content: center;
  align-items: center;
  width: 500px !important;
  position: absolute;
  left: 70%;
  right: 50%;
  top: 20%;
}
.label_stay {
  width: 196px;
  height: 38px;
  border-radius: 4px;
  background-color: #e3ebf3;
}
.starcolor {
  color: red;
  font-weight: 500;
  font-size: 20px;
}

@media (max-width: 1024px) {
  .modal-content {
    left: 45%;
    right: 40%;
    top: 20%;
  }
}
@media (max-width: 768px) {
  .modal-content {
    left: 35%;
    right: 40%;
    top: 20%;
  }
}
