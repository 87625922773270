.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure that the modal is displayed above the blurred background */
}

.modal.open {
  display: flex;
}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px); /* Add a blur effect to the entire background */
}

.modal-content {
  background-color: white;
  width: 350px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 2; /* Ensure that the modal content is displayed above the blurred background */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.btn {
  font-size: 16px;
  color: white;
  border-radius: 4px;
  padding: 4px 10px 4px 10px;
}
.cancel {
  background-color: red;
  /* margin-right: 2px; */
}
.save {
  background-color: #01a768;
}
